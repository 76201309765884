import React, {RefObject} from "react";
import Label from "../../../../../components/Label/Label";
import Input from "../../../../../components/Input/Input";
import {ResultError, Return} from "../../../../../lib/result";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import Section from "../../../../../components/Drawer/Section/Section";
import LabelsAnnotationsSection, {LabelsAnnotationsSectionProps} from "../../../../../components/Drawer/Sections/Metadata/LabelsAnnotationsSection";
import Description from "../../../../../components/Description/Description";
import constants from "../../../../../constants/constants";
import {randomString} from "../../../../../lib/helper";
import SectionExpander from "../../../../../components/Drawer/SectionExpander/SectionExpander";
import {ClusterV1Space} from "../../../../../../gen/models/clusterV1Space";
import {displayNameToID} from "../../../../../components/Drawer/Sections/Metadata/Metadata";

interface MetadataState {
    name: string | undefined;
    nameChanged: boolean;

    displayName: string | undefined;

    description: string | undefined;
}

interface MetadataProps extends SectionProps, LabelsAnnotationsSectionProps {
    space?: ClusterV1Space;

    noMargin?: boolean;
}

export default class SpaceMetadata extends React.PureComponent<MetadataProps, MetadataState> {
    labelsSectionRef?: RefObject<LabelsAnnotationsSection>;
    state: MetadataState = {
        name: this.props.space ? this.props.space?.metadata?.name : undefined,
        nameChanged: false,

        displayName: this.props.space ? this.props.space?.metadata?.annotations?.[constants.LoftDisplayNameAnnotation] : undefined,

        description: this.props.space ? this.props.space?.metadata?.annotations?.[constants.LoftDescriptionAnnotation] : undefined,
    };

    constructor(props: MetadataProps) {
        super(props);

        this.labelsSectionRef = React.createRef<LabelsAnnotationsSection>();
    }

    create = async (space: ClusterV1Space): Promise<ResultError> => {
        let name = this.state.name;
        if (!this.state.name) {
            name = "myspace-" + randomString(5);
        }

        // make sure metadata exists
        if (!space.metadata) {
            space.metadata = {};
        }
        if (!space.metadata.annotations) {
            space.metadata.annotations = {};
        }

        space.metadata.name = name;
        if (this.state.displayName) {
            space.metadata.annotations[constants.LoftDisplayNameAnnotation] = this.state.displayName;
        }
        if (this.state.description) {
            space.metadata.annotations[constants.LoftDescriptionAnnotation] = this.state.description;
        }
        return this.labelsSectionRef!.current!.create(space.metadata);
    };

    update = (space: ClusterV1Space): ResultError => {
        if (this.state.displayName) {
            if (!space.metadata?.annotations) {
                space.metadata!.annotations = {};
            }
            space.metadata!.annotations[constants.LoftDisplayNameAnnotation] = this.state.displayName;
        } else if (space.metadata?.annotations) {
            delete space.metadata.annotations[constants.LoftDisplayNameAnnotation]
        }
        if (this.state.description) {
            if (!space.metadata?.annotations) {
                space.metadata!.annotations = {};
            }
            space.metadata!.annotations[constants.LoftDescriptionAnnotation] = this.state.description;
        } else if (space.metadata?.annotations) {
            delete space.metadata.annotations[constants.LoftDescriptionAnnotation]
        }

        return this.labelsSectionRef!.current!.update(space.metadata!);
    };

    batch = (spaces: ClusterV1Space[]): ResultError => {
        return this.labelsSectionRef!.current!.batch(spaces.map(space => space.metadata!));
    };

    renderInfo() {
        return <div>
            <div className={"row"}>
                <div>
                    <Label>Display Name</Label>
                    <Input placeholder={"My Space"} value={this.state.displayName} onChange={(e) => {
                        if (this.props.mode === "create" && !this.state.nameChanged) {
                            this.setState({displayName: e.target.value, name: displayNameToID(e.target.value)})
                        } else {
                            this.setState({displayName: e.target.value})
                        }
                    }} />
                </div>
                <div>
                    <Label>Kubernetes Name (ID)</Label>
                    <Input readOnly={this.props.mode === "update"} placeholder={"myspace"} value={this.state.name} onChange={(e) => this.setState({name: e.target.value, nameChanged: true})} />
                </div>
            </div>
            <SectionExpander name={"Description"}>
                <Label>Description</Label>
                <Input placeholder={`The Space is doing...`}
                       value={this.state.description}
                       onChange={e => this.setState({description: e.target.value})} />
                <Description>A human readable description what the space is used for. Markdown can be used here.</Description>
            </SectionExpander>
        </div>
    }

    render() {
        return <Section title={`Metadata`} noMargin={this.props.noMargin}>
            {this.props.mode !== "batch" && this.renderInfo()}
            <LabelsAnnotationsSection {...this.props} metadata={this.props.space ? this.props.space.metadata : undefined} filterAnnotations={[constants.LoftDisplayNameAnnotation, constants.LoftDescriptionAnnotation]} ref={this.labelsSectionRef} />
        </Section>
    }
}