import React from "react";
import {ResultError, Return} from "../../../../../lib/result";
import {SectionProps} from "../../../../../components/Drawer/ItemDrawer";
import Label from "../../../../../components/Label/Label";
import * as yaml from "js-yaml";
import {arr} from "../../../../../lib/helpers/renderhelper";
import styles from "./OtherResources.module.scss";
import YAMLEditor from "../../../../../components/YAMLEditor/YAMLEditor";
import {ManagementV1ClusterAccountTemplate} from "../../../../../../gen/models/managementV1ClusterAccountTemplate";
import {isAccountQuota} from "./AccountQuota";
import {isClusterRoleBinding} from "./ClusterRoleBindings";
import Description from "../../../../../components/Description/Description";
import SectionExpander from "../../../../../components/Drawer/SectionExpander/SectionExpander";

interface OtherResourcesState {
    manifests: string;
}

interface OtherResourcesProps extends SectionProps {
    clusterAccountTemplate?: ManagementV1ClusterAccountTemplate;
}

function filterManifests(clusterAccountTemplate: ManagementV1ClusterAccountTemplate): any[] {
    return arr(clusterAccountTemplate.spec?.owns).filter(resource => !isAccountQuota(resource) && !isClusterRoleBinding(resource));
}

function reverseFilterManifests(clusterAccountTemplate: ManagementV1ClusterAccountTemplate): any[] {
    return arr(clusterAccountTemplate.spec?.owns).filter(resource => isAccountQuota(resource) || isClusterRoleBinding(resource));
}

export default class OtherResources extends React.PureComponent<OtherResourcesProps, OtherResourcesState> {
    state: OtherResourcesState = {
        manifests: !this.props.clusterAccountTemplate ? "" : filterManifests(this.props.clusterAccountTemplate).length > 0 ? filterManifests(this.props.clusterAccountTemplate).map(manifest => yaml.dump(manifest)).join("\n---\n") : "",
    };

    create = async (clusterAccountTemplate: ManagementV1ClusterAccountTemplate): Promise<ResultError> => {
        return this.update(clusterAccountTemplate);
    };

    update = async (clusterAccountTemplate: ManagementV1ClusterAccountTemplate): Promise<ResultError> => {
        if(!clusterAccountTemplate.spec) {
            clusterAccountTemplate.spec = {};
        }

        // manifests
        const filteredManifests = this.state.manifests.split("\n---\n");
        const parsedManifests: object[] = [];
        for (let i = 0; i < filteredManifests.length; i++) {
            try {
                const parsed = yaml.safeLoad(filteredManifests[i]);
                if (parsed) {
                    parsedManifests.push(parsed);
                }
            } catch(e: any) {
                return Return.Failed(`Error parsing ${filteredManifests[i]}: ${e.message}`);
            }
        }
        clusterAccountTemplate.spec.owns = [...reverseFilterManifests(clusterAccountTemplate), ...parsedManifests];
        return Return.Ok();
    };

    render() {
        if (this.props.mode === "batch") {
            return null;
        }

        return <SectionExpander name={`Other Resources`}>
            <Label>Other Kubernetes Manifests</Label>
            <YAMLEditor value={this.state.manifests} minLines={10} maxLines={100} onChange={val => {
                this.setState({manifests: val});
            }} placeholder={`apiVersion: config.kiosk.sh/v1alpha1
kind: AccountQuota
spec:
  account: '${"${LOFT_ACCOUNT_NAME}"}'
quota:
...`} />
            <Description className={styles["description"]}>These resources will be deployed in the target cluster with an owner reference to the account. Use {"${LOFT_ACCOUNT_NAME}"} as a placeholder for the account name. If no resource name is set, a name will be generated during deployment. {this.props.mode !== "create" ? "Leave the text box empty to delete a resource." : ""}</Description>
        </SectionExpander>
    }
}